<template>
  <div>
    <el-dialog
      title="消费日志明细"
      :visible.sync="showDialog"
      width="1000px"
      top="2%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <div class="content-box">
        <template v-if="type == 'expense'">
          <el-form label-width="auto" size="mini">
            <div class="df-row">
              <div class="df-col">
                <el-form-item label="单号">{{ logInfo.BillNo }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="状态">{{
                  logInfo.CancelMark
                }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="记账日期">{{
                  util.dateFormat(
                    "YYYY-mm-dd",
                    new Date(logInfo.SoftwareDate * 1000)
                  )
                }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="操作时间">{{
                  util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(logInfo.CheckoutTime * 1000)
                  )
                }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="操作门店">{{
                  logInfo.StoresName
                }}</el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div class="df-col">
                <el-form-item label="账单金额">
                  <div>{{ logInfo.BillMoney }}</div>
                </el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="折扣金额">{{
                  logInfo.BillDisc
                }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="实收金额">{{
                  logInfo.BillAmount
                }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="零头数">{{
                  logInfo.BillZero
                }}</el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div :span="rowSpan">
                <el-form-item label="付款方式">
                  <span
                    v-for="(item, index) in logInfo.PaymentList"
                    :key="index"
                    >{{ `${item.PaymentName}：${item.PayMoney}元` }}</span
                  >
                </el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div :span="rowSpan">
                <el-form-item label="服务员工">
                  <span
                    v-for="(item, index) in logInfo.EmplList"
                    :key="index"
                    >{{
                      `${item.DutyName}：${item.EmplNo}_${item.EmplName}${
                        logInfo.EmplList.length == 1 ? "" : "，"
                      }`
                    }}</span
                  >
                </el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div :span="rowSpan">
                <el-form-item label="账单备注">{{
                  logInfo.BillNote
                }}</el-form-item>
              </div>
            </div>
          </el-form>

          <el-table :data="logInfo.ItemList" max-height="400px" border stripe>
            <el-table-column prop="" label="类型">消费项目</el-table-column>
            <el-table-column prop="ItemNo" label="编号"></el-table-column>
            <el-table-column
              prop="ItemName"
              label="项目"
              width="100"
            ></el-table-column>
            <el-table-column prop="ItemPrice" label="单价"></el-table-column>
            <el-table-column prop="Consume" label="消耗额"></el-table-column>
            <el-table-column prop="ItemCount" label="数量"></el-table-column>
            <el-table-column prop="ItemMoney" label="总价"></el-table-column>
            <el-table-column prop="ItemDisc" label="折扣"></el-table-column>
            <el-table-column prop="ItemAmount" label="实收"></el-table-column>
            <el-table-column prop="ItemZero" label="零头数"></el-table-column>
            <el-table-column prop="ExpeItemNote" label="备注"></el-table-column>
            <el-table-column
              prop="CreateAdmin"
              label="新增者"
            ></el-table-column>
            <el-table-column prop="" label="新增时间" width="160">
              <template slot-scope="scope">
                {{
                  scope.row.CreateTime
                    ? util.dateFormat(
                        "YYYY-mm-dd HH:MM:SS",
                        new Date(scope.row.CreateTime * 1000)
                      )
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="UpdateAdmin"
              label="修改者"
            ></el-table-column>
            <el-table-column prop="" label="修改时间" width="160">
              <template slot-scope="scope">
                {{
                  scope.row.CreateTime
                    ? util.dateFormat(
                        "YYYY-mm-dd HH:MM:SS",
                        new Date(scope.row.UpdateTime * 1000)
                      )
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="是否删除">
              <template slot-scope="scope">
                <span>{{ scope.row.DeleteMark ? "是" : "否" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-if="type == 'card'">
          <el-form label-width="auto" size="mini">
            <div class="df-row">
              <div class="df-col">
                <el-form-item label="单号">{{ logInfo.BillNo }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="类型">{{logInfo.KeepTypeText}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="记账日期">{{util.dateFormat(
                    "YYYY-mm-dd",
                    new Date(logInfo.SoftwareDate * 1000)
                  )}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="操作时间">{{util.dateFormat(
                    "YYYY-mm-dd HH:MM:SS",
                    new Date(logInfo.KeepTime * 1000)
                  )}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="操作门店">{{logInfo.StoresName}}</el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div class="df-col">
                <el-form-item label="充值金额">{{ logInfo.KeepMoney }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="赠送金额">{{logInfo.DonaMoney}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="欠款金额">{{logInfo.KeepDebt}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="充值积分">{{logInfo.KeepInte}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="状态">{{logInfo.CancelMark}}</el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div class="df-col">
                <el-form-item label="开卡金额">{{ logInfo.Price }}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="开工本费">{{logInfo.Cost}}</el-form-item>
              </div>
              <div class="df-col">
                <el-form-item label="账单备注">{{logInfo.KeepNote}}</el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div :span="rowSpan">
                <el-form-item label="付款方式">
                  <span
                    v-for="(item, index) in logInfo.PaymentList"
                    :key="index"
                    >{{ `${item.PaymentName}：${item.PayMoney}元` }}</span
                  >
                </el-form-item>
              </div>
            </div>
            <div class="df-row">
              <div :span="rowSpan">
                <el-form-item label="服务员工">
                  <span
                    v-for="(item, index) in logInfo.EmplList"
                    :key="index"
                    >{{
                      `${item.DutyName}：${item.EmplNo}_${item.EmplName}${
                        logInfo.EmplList.length == 1 ? "" : "，"
                      }`
                    }}</span
                  >
                </el-form-item>
              </div>
            </div>
          </el-form>
        </template>
      </div>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import System from "@/api/system.js";

export default {
  data() {
    return {
      rowSpan: 24,
      logGuid: "",
      type: "expense", // expense: 消费消单 card: 开充卡消单
      logInfo: {},
      showDialog: false,
    };
  },

  mounted() {},

  methods: {
    // 获取日志明细
    async getLogDetail() {
      try {
        let { data } = await System.getLogDetail({
          log_guid: this.logGuid,
        });
        this.logInfo = data;
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化页面数据
    initPageData() {
      this.logInfo =
        this.type === "expense"
          ? {
              BillAmount: 0,
              BillDisc: 0,
              BillMoney: 0,
              BillNo: "",
              BillNote: "",
              BillZero: 0,
              CancelMark: "",
              CheckoutTime: 0,
              EmplList: [],
              ItemList: [],
              PaymentList: [],
              SoftwareDate: 0,
              StoresName: "",
            }
          : {
              BillNo: "",
              CancelMark: "",
              Cost: 0,
              DonaMoney: 0,
              EmplList: [],
              KeepDebt: 0,
              KeepInte: 0,
              KeepMoney: 0,
              KeepNote: "",
              KeepTime: 0,
              KeepType: 0,
              KeepTypeText: "",
              PaymentList: [],
              Price: 0,
              SoftwareDate: 0,
              StoresName: "",
            };
    },

    // 打开对话框
    async onShowDialog(logGuid = "", type = "expense") {
      this.logGuid = logGuid;
      this.type = type;
      await this.initPageData();
      await this.getLogDetail();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-row {
    .df-col {
      min-width: calc(100% / 6.4);
    }
  }
}
</style>
