import { request } from "../utils/axios.js";

export default {
	// 初始化日志
	initLogList(params={}){
		return request('GET','/system/init_log_list');
	},

	// 获取日志列表
	getLogList(params={}){
		return request('GET','/system/get_log_list', params);
	},

	// 获取日志明细
	getLogDetail(params={}){
		return request('GET','/system/get_log_detail', params);
	},

	// 初始化分管门店列表
	initListStoresGuid(params={}){
		return request('GET','/system/init_list_stores_guid', params);
	},

	// 分配分管门店
	updateListStoresGuid(params={}){
		return request('POST','/system/update_list_stores_guid', params);
	},

	// 重置管理员密码
	resetPhonePassword(params={}){
		return request('GET','/system/reset_phone_password', params);
	},

	// 复制权限
	copyManagerPower(params={}){
		return request('POST','/system/copy_manager_power', params);
	},
}